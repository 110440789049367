import { components__address__i18n__addressForCountry } from './address-i18n.js'
/**
 * The formatters-custom file is a place to define custom formatters or to
 * override any of the baked-in formatters like phone number formatting.
 *
 * e.g. You may write something like below
 * export function address(profile) {
 *   return `111 Yext Street`;
 * }
 */

// custom formatter for prices used on card
export function prices(profile) {
  if (profile.c_variationPrice2) {
      return profile.c_price + ' | ' + profile.c_variationPrice1 + ' | ' + profile.c_variationPrice2;
  }
  else if (profile.c_variationPrice1) {
      return profile.c_price + ' | ' + profile.c_variationPrice1;
  }
  return profile.c_price;
}

export function address(profile) {
  if (!profile.address) {
    return '';
  }
  return components__address__i18n__addressForCountry({
    profile: profile,
    derivedData: {address: {stateName: ''}},
    regionAbbr: true,
  });
}

